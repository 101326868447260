* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.certficateBody {
  width: 100%;
  /* max-width: 700px; */
  background-color: rgb(255, 255, 255);
  padding: 10px;
}
.logoM {
  width: 100%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logo p {
  margin: 0 !important;
  font-family: math;
}
.headP {
  width: 100%;
  text-align: center;
  text-decoration: underline;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  color: rgb(45, 140, 255);
}
.certFormat {
  padding: 2rem 1.5rem;
}
.certFormat p {
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: #2f2f2f;
}
.certFormat p span {
  font-weight: 700;
  color: #2f2f2f;
}
.footerC {
  width: 100%;
  padding: 2rem 7rem;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: end;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-transform: capitalize;
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-toolbar {
  padding: 10px;
}
.eventType {
  width: 100%;
}
.css-1byr0tz {
  padding: 1rem;
  padding-top: 94px;
  padding-bottom: 80px;
}
h3 {
  font-size: 1.2rem !important;
}
/* ::-webkit-scrollbar {
  display: none !important;
} */
.fc table {
  font-size: 1.2rem;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #a69fff;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: #ababad !important;
  border: none;
}
.fc .fc-button-primary:disabled {
  background-color: #72b6eb !important;
  color: rgb(255, 255, 255) !important;
  border: none;
  opacity: 1 !important;
  text-transform: capitalize;
}
.eventTable {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.fc .fc-daygrid-day-frame {
  /* max-height: 140px; */
}

@media screen and (max-width: 600px) {
  .fc-toolbar-chunk {
    flex-direction: column;
  }
  .fc-toolbar-title {
    font-size: 1.2rem !important;
  }
  .fc-today-button {
    display: none !important;
  }
  .fc-button {
    font-size: 0.8rem;
  }
  .fc .fc-toolbar-title {
    font-size: 1.2em;
  }
  .cuName,
  .eventTyp,
  .bookTyp {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 990px) {
  .cuName,
  .eventTyp,
  .bookTyp {
    font-size: 0.6rem;
  }
}
.cuName,
.eventTyp,
.bookTyp {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: capitalize !important;
  padding: 0 2px;
  margin: 0 !important;
  font-size: 0.7rem;
}
.bookingLabel {
  background-color: rgb(17, 108, 120);
  color: rgb(240, 240, 240);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  padding: 5px;
  /* margin: 10px 0; */
  width: 100% !important;
}
.bookingLabel button {
  padding: 0 !important;
}
.enquiryLabel {
  background-color: #e16e26;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  padding: 5px;
  /* margin: 10px 0; */
}
#bookLabel .eventTyp {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.656);
  margin: 10px 0;
}

#bookLabel {
  cursor: pointer;
}
.fc-h-event {
  border: none !important;
  /* background-color: rgb(4, 0, 255) !important; */
}
.fc-day-past {
  opacity: 0.6 !important;
  color: rgb(198, 198, 198) !important;
  /* background-color: #f2f2f3 !important; */
}
.fc-day-past .bookingLabel {
  background-color: rgb(17, 108, 120);
}
.fc-day-past .enquiryLabel {
  background-color: #e16e26;
}
.indication {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
}
.indication p {
  display: flex;
  align-items: center;
  gap: 5px;
}
.indication .bookngLabel {
  background-color: rgb(17, 108, 120);
  width: 15px;
  height: 15px;
  border-radius: 5px;
}
.indication .enqLabel {
  background-color: #e16e26;
  width: 15px;
  height: 15px;
  border-radius: 5px;
}
.calFoot {
  width: 100%;
}
.calFoot td {
  border: 1px solid var(--fc-border-color, #ddd);
  text-align: center;
  width: 200px;
  font-size: 1.1rem;
  font-family: sans-serif;
  text-decoration: none;
  color: #4f5a78;
  font-weight: 700;
  font-family: sans-serif;
  text-transform: capitalize;
  position: sticky;
  /* width: 100%; */
}
/* .actionBtns{
  width:150px;
  display: flex;
  flex-wrap: wrap;
 
} */
/* .fc-view-harness .fc-view-harness-active{
  height: 500px !important;
} */
.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
  font-size: 1.1rem;
  font-family: sans-serif;
  text-decoration: none;
  color: #4f5a78;
}
.addStaffs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.stffInput {
  width: 100%;
  margin-bottom: 15px;
}
#noData_avail {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
  font-weight: 700 !important;
}

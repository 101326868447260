* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.scTable {
  background-color: rgb(234, 234, 234);
}
.ant-table-thead > tr > th {
  background-color: rgb(255, 255, 255) !important;
}
.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 0;
  background-color: #fff;
}
#printablediv {
  max-width: 700px !important;
  width: 100% !important;
  max-width: 650px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  height: 100%;
  /* border: 1px solid black; */
}
.resBody {
  padding: 1.5rem;
}
#printableMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerPay {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.headerInp {
  width: 14%;
}
.headerDT {
  width: 43%;
}
p {
  margin: 0 !important;
  margin: 0 !important;
}
#payTable p {
  margin: 0 !important;
  padding: 5px !important;
}
.ant-modal-body {
  padding: 15px !important;
}
.ant-divider-horizontal {
  margin: 20px 0 !important;
}
.paymentBody {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 15px;
}
.paymentBody .staffname {
  width: 25%;
}
.paymentFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;
}
.amountSection h3 {
  text-align: right;
  font-size: 14px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.doubleLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.payDivider {
  margin: 5px 0 !important;
}
.css-de9k3v-MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.printInfo {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1.5rem;
  /* justify-content: center; */
  flex-direction: column;
}

.actionsRec {
  max-width: 780px !important;
  width: 100% !important;
  gap: 1.5rem;
  padding: 1rem;
  display: flex !important;
  justify-content: flex-end;
}

.save-btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.printBTN {
  color: white;
  background-color: black;
  position: absolute;
  top: 38px;
  left: 56px;
}

@media print {
  #printPageButton {
    display: none;
  }
}
.recHeader {
  max-width: 648px !important;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  padding: 1rem 10px 1rem 10px;
  align-items: center;
}
.dheader {
  max-width: 648px !important;
  width: 100% !important;
  text-align: center;
  background-color: #fff;
}
.dheader h3 {
  margin: 0 !important;
}

.cInfo p {
  font-size: 12px;
  color: rgb(0, 0, 0);
  text-transform: none !important;
}
.cInfo .title {
  font-size: 12px;
  color: black;
}
p {
  text-transform: none;
}
@media screen and (max-width: 767px) {
  .recieInfo,
  .cInfo {
    font-size: 10px;
  }
  .recieheader,
  .recHeader {
    flex-direction: column;
    justify-content: center;
  }

  .cInfo p,
  .recieInfo {
    text-align: center !important;
  }
}
.recieBody {
  width: 100%;
  max-width: 700px;
  padding: 1rem;
  /* border: 1px solid rgb(101, 101, 101); */
  border-radius: 5px;
  background-color: #fff;
}
.recieheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recieLogo img {
  width: 7rem;
}
.recieInfo {
  position: relative;
  text-align: right;
}
.typeRecei {
  width: 100%;
  text-align: center;
}
.recei_content_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.css-hc3d9-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}
@media screen and (max-width:767px) {
  .printInfo{
    padding: 1.5rem 0rem;
  }
  .printBTN {
    color: white;
    background-color: black;
    position: absolute;
    top: 35px;
    left: 24px;
  }
  .recieBody{
    padding: 10px;
  }
  
}
.dateSel {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  gap: 15px;
  align-items: center;
  /* justify-content: space-between; */
}
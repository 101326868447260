* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.headerSection {
  width: 100%;
  max-width: 585px;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 15px;
}
.headerSection #date {
  width: 9.8rem;
}
.saveBtn {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.missingIput {
  text-align: center;
  border: none;
  width: 4rem;
}
.css-y77xj8-MuiTableCell-root, .css-19jna5v-MuiTableCell-root{
  border-bottom: 1px solid rgb(200 203 205) ;
}
.css-1nlrwzv-MuiTableCell-root, .css-1jyvvl3-MuiTableCell-root,.css-wcjgd7-MuiTableRow-root:last-child td, .css-wcjgd7-MuiTableRow-root:last-child th{
  border-bottom: 1px solid rgb(200 203 205);
}
.css-oldthp-MuiTable-root,.css-14ui2h6{
  border: 1px solid  rgb(200 203 205);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h3 {
  font-size: 1rem !important;
}

 /* width */

 .css-pwhq7a-MuiTypography-root{
  font-weight: 800 !important
}
@media screen and (max-width:580px) {
  .userPrivillage{
    grid-template-columns: 1fr;
  }
  
}

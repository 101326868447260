* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.feedBackContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
}
.feedbackBody {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
}
